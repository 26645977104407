
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-home",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/customer",
    "label": "Customers",
    "icon": "pi pi-user-edit",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/user",
    "label": "Admins",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
    
  }
],
	gender: [    
{value: "Male", label: "Male"},
	{value: "Female", label: "Female"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}