
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//address routes
			{
				path: '/address/:fieldName?/:fieldValue?',
				name: 'addresslist',
				component: () => import('./pages/address/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/address/view/:id', 
				name: 'addressview', 
				component: () => import('./pages/address/view.vue'), 
				props: true
			},
		
			{ 
				path: '/address/add', 
				name: 'addressadd', 
				component: () => import('./pages/address/add.vue'), 
				props: true
			},
	
			{ 
				path: '/address/edit/:id', 
				name: 'addressedit', 
				component: () => import('./pages/address/edit.vue'), 
				props: true
			},
		

//customer routes
			{
				path: '/customer/:fieldName?/:fieldValue?',
				name: 'customerlist',
				component: () => import('./pages/customer/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/customer/view/:id', 
				name: 'customerview', 
				component: () => import('./pages/customer/view.vue'), 
				props: true
			},
		
			{ 
				path: '/customer/add', 
				name: 'customeradd', 
				component: () => import('./pages/customer/add.vue'), 
				props: true
			},
	
			{ 
				path: '/customer/edit/:id', 
				name: 'customeredit', 
				component: () => import('./pages/customer/edit.vue'), 
				props: true
			},
		

//person routes
			{
				path: '/person/:fieldName?/:fieldValue?',
				name: 'personlist',
				component: () => import('./pages/person/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/person/view/:id', 
				name: 'personview', 
				component: () => import('./pages/person/view.vue'), 
				props: true
			},
		
			{ 
				path: '/person/add', 
				name: 'personadd', 
				component: () => import('./pages/person/add.vue'), 
				props: true
			},
	
			{ 
				path: '/person/edit/:id', 
				name: 'personedit', 
				component: () => import('./pages/person/edit.vue'), 
				props: true
			},
		

//transaction routes
			{
				path: '/transaction/:fieldName?/:fieldValue?',
				name: 'transactionlist',
				component: () => import('./pages/transaction/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/transaction/view/:id', 
				name: 'transactionview', 
				component: () => import('./pages/transaction/view.vue'), 
				props: true
			},
		
			{ 
				path: '/transaction/add', 
				name: 'transactionadd', 
				component: () => import('./pages/transaction/add.vue'), 
				props: true
			},
	
			{ 
				path: '/transaction/edit/:id', 
				name: 'transactionedit', 
				component: () => import('./pages/transaction/edit.vue'), 
				props: true
			},
		

//user routes
			{
				path: '/user/:fieldName?/:fieldValue?',
				name: 'userlist',
				component: () => import('./pages/user/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/user/view/:id', 
				name: 'userview', 
				component: () => import('./pages/user/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/registerrz', 
				name: 'useruserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'useraccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'useraccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/user/add', 
				name: 'useradd', 
				component: () => import('./pages/user/add.vue'), 
				props: true
			},
	
			{ 
				path: '/user/edit/:id', 
				name: 'useredit', 
				component: () => import('./pages/user/edit.vue'), 
				props: true
			},
		

//verification routes
			{
				path: '/verification/:fieldName?/:fieldValue?',
				name: 'verificationlist',
				component: () => import('./pages/verification/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/verification/view/:id', 
				name: 'verificationview', 
				component: () => import('./pages/verification/view.vue'), 
				props: true
			},
		
			{ 
				path: '/verification/add', 
				name: 'verificationadd', 
				component: () => import('./pages/verification/add.vue'), 
				props: true
			},
	
			{ 
				path: '/verification/edit/:id', 
				name: 'verificationedit', 
				component: () => import('./pages/verification/edit.vue'), 
				props: true
			},
		

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
		{ 
			path: '/index/accountcreated', 
			name: 'accountcreated', 
			component: () => import('./pages/index/accountcreated.vue'), 
			props: true
		},
		{
			path: '/index/accountpending', 
			name: 'accountpending', 
			component: () => import('./pages/index/accountpending.vue'), 
			props: true
		},
		{ 
			path: '/index/accountblocked',
			name: 'accountblocked', 
			component: () => import('./pages/index/accountblocked.vue'), 
			props: true
		},

	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		
		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/',  query: { nexturl: to.fullPath } });
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

		//close dialog from previous page
		//store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
